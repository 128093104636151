import { AnchorLink } from 'gatsby-plugin-anchor-links'
import React from 'react'
import AppStoreDarkSmallSVG from '../icons/appStoreDarkSmallSVG'
import FacebookSVG from '../icons/facebookSVG'
import Logo from '../icons/logo'
import TwitterSVG from '../icons/twitterSVG'
import UpButtonMobile from '../icons/upButtonMobile'

export default function Footer({ appStoreLink, facebookLink, twitterLink }) {
  const scrollTop = () => {
    const y = 0
    const duration = 1500
    var initialY = document.documentElement.scrollTop || document.body.scrollTop
    var baseY = (initialY + y) * 0.5
    var difference = initialY - baseY
    var startTime = performance.now()

    function step() {
      var normalizedTime = (performance.now() - startTime) / duration
      if (normalizedTime > 1) normalizedTime = 1

      window.scrollTo(
        0,
        baseY + difference * Math.cos(normalizedTime * Math.PI)
      )
      if (normalizedTime < 1) window.requestAnimationFrame(step)
    }
    window.requestAnimationFrame(step)
  }

  return (
    <footer>
      <div className="w-full md:flex md:flex-row-reverse md:items-end layout-space lg:py-6">
        <div className="relative flex py-10 md:w-1/2 lg:w-1/3">
          <button
            onClick={scrollTop}
            className="md:hidden"
            aria-label="Scroll to the top"
          >
            <UpButtonMobile className="mr-4 return-to-top" />
          </button>
          <ul className="w-1/2">
            <li className="mb-3">
              <AnchorLink className="nav-link" to="/#why">
                Por Que Nós
              </AnchorLink>
            </li>
            <li className="mb-3">
              <AnchorLink className="nav-link" to="/#features">
                Recursos
              </AnchorLink>
            </li>
            <li className="mb-3">
              <AnchorLink className="nav-link" to="/#pricing">
                Preços
              </AnchorLink>
            </li>
            <li className="">
              <AnchorLink className="nav-link" to="/#reviews">
                Avaliações
              </AnchorLink>
            </li>
          </ul>
          <ul className="w-1/2">
            <li className="mb-3">
              <AnchorLink className="nav-link" to="/#contact">
                Contato
              </AnchorLink>
            </li>
            <li className="mb-3">
              <AnchorLink className="nav-link" to="/faq">
                FAQ
              </AnchorLink>
            </li>
            <li className="mb-3">
              <AnchorLink className="nav-link" to="/privacy">
                Privacy Policy
              </AnchorLink>
            </li>
            <li className="">
              <AnchorLink className="nav-link" to="/terms">
                Terms and Conditions
              </AnchorLink>
            </li>
          </ul>
        </div>
        <div className="md:w-1/2 lg:w-2/3">
          <div className="flex items-center justify-center mb-8 md:justify-start lg:mb-12">
            <p className="hidden mr-6 text-black md:block">Follow Us</p>
            <a href={facebookLink} target="_blank" rel="noreferrer">
              <FacebookSVG className="mr-3 social-icon" />
            </a>
            <a href={twitterLink} target="_blank" rel="noreferrer">
              <TwitterSVG className="ml-3 social-icon" />
            </a>
          </div>
          <p className="mb-8 text-center small md:mb-10 md:text-left">
            &copy; 2021 DeepSine Ltd. All rights reserved.
          </p>
        </div>
      </div>
      <div className="flex justify-between px-4 py-2 bg-white md:hidden">
        <div className="flex items-center">
          <Logo className="w-8 h-8 mr-4" />
          <div>
            <h3 className="text-xs font-semibold font-title">
              Ditado de Voz<br/>Transcrição instantanea
            </h3>
          </div>
        </div>
        <a href={appStoreLink} target="_blank" rel="noreferrer">
          <AppStoreDarkSmallSVG />
        </a>
      </div>
    </footer>
  )
}
